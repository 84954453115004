import { apiSlice } from '../api-slice'
import { DocumentChangeTemplateInterface } from '../../shared/interfaces/project/document/document-change/document-change-template.interface'
import {
  DocumentChange,
  DocumentChangeTaskStatus,
} from '../../shared/interfaces/project/document/changes/document-change.interface'
import { ProjectDocument } from '../../shared/interfaces/project/document/document.interface'

export interface UpdateDocumentChange {
  change_type: 'delete' | 'amend' | 'create' | 'replace'
  original_clause: string
  original_clause_reference: string
  reconciled_clause: string
  document?: number
  comment?: string
  status?: DocumentChangeTaskStatus
  id?: number
  projectUUID?: string
  documentUUID?: string
  qa_checked?: boolean
}

const documentChangeTemplateApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentTemplates: builder.query<
      DocumentChangeTemplateInterface[],
      string
    >({
      query: (ccdcVersion) =>
        `/documentchangetemplates/?version=${ccdcVersion}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'DocumentChangeTemplate' as const,
                id,
              })),
              { type: 'DocumentChangeTemplate' as const, id: 'LIST' },
            ]
          : [{ type: 'DocumentChangeTemplate' as const, id: 'LIST' }],
    }),
    getCCDCVersions: builder.query<string[], void>({
      query: () => '/documentchangetemplates/versions/',
    }),
    markSupplementaryConditionsQAComplete: builder.mutation<
      void,
      Partial<ProjectDocument>
    >({
      query: ({ project }) => ({
        url: `/changes/complete/`,
        method: 'POST',
        body: { project },
      }),
      onQueryStarted: async ({ uuid }, { dispatch, queryFulfilled }) => {
        const createResult = dispatch(
          apiSlice.util.updateQueryData(
            'getDocumentByUuid',
            uuid ?? '',
            (draft) => {
              if (!draft) {
                return
              }
              draft = {
                ...draft,
                review: {
                  id: uuid ?? '',
                  review_type: 'SUPPLEMENTARY_CONDITIONS',
                },
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          createResult.undo()
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Document', id: arg.id },
        { type: 'Document', id: 'LIST' },
      ],
    }),
    markAddendaQAComplete: builder.mutation<void, Partial<ProjectDocument>>({
      query: ({ project }) => ({
        url: `/changes/addenda_complete/`,
        method: 'POST',
        body: { project },
      }),
      onQueryStarted: async ({ uuid }, { dispatch, queryFulfilled }) => {
        const createResult = dispatch(
          apiSlice.util.updateQueryData(
            'getDocumentByUuid',
            uuid ?? '',
            (draft) => {
              if (!draft) {
                return
              }
              draft.review = {
                id: uuid ?? '',
                review_type: 'ADDENDA',
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          createResult.undo()
        }
      },
      invalidatesTags: [{ type: 'Document', id: 'LIST' }],
    }),
    createDocumentChange: builder.mutation<DocumentChange, DocumentChange>({
      query: (documentChange) => ({
        url: '/changes/',
        method: 'POST',
        body: documentChange,
      }),
      onQueryStarted: async (documentChange, { dispatch, queryFulfilled }) => {
        const createResult = dispatch(
          apiSlice.util.updateQueryData(
            'getDocumentChangesByProject',
            documentChange?.projectUUID ?? '',
            (draft) => {
              draft.push(documentChange)
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          createResult.undo()
        }
        // return queryFulfilled;
      },
      invalidatesTags: [{ type: 'Change', id: 'LIST' }],
    }),
    updateDocumentChange: builder.mutation<
      DocumentChange,
      UpdateDocumentChange
    >({
      query: (documentChange) => ({
        url: `/changes/${documentChange?.id}/`,
        method: 'PATCH',
        body: documentChange,
      }),
      onQueryStarted: async (documentChange, { dispatch, queryFulfilled }) => {
        const createResult = dispatch(
          apiSlice.util.updateQueryData(
            'getDocumentChangesByProject',
            documentChange?.projectUUID ?? '',
            (draft) => {
              const index = draft.findIndex((d) => d?.id === documentChange?.id)
              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  ...documentChange,
                }
              }
            }
          )
        )
        try {
          const itemData = (await queryFulfilled).data
          dispatch(
            apiSlice.util.updateQueryData(
              'getDocumentChangesByProject',
              itemData?.projectUUID ?? '',
              (draft) => {
                const index = draft.findIndex((d) => d?.id === itemData?.id)
                if (index !== -1) {
                  draft[index] = {
                    ...draft[index],
                    ...itemData,
                  }
                }
              }
            )
          )
        } catch {
          createResult.undo()
        }
        // return queryFulfilled;
      },
      invalidatesTags: [{ type: 'Change', id: 'LIST' }],
    }),
    deleteDocumentChange: builder.mutation<DocumentChange, DocumentChange>({
      query: ({ id, source }) => ({
        url: `/changes/${id}/?document=${source.document_uuid}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (documentChange, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            'getDocumentChangesByProject',
            documentChange.projectUUID ?? '',
            (draft) => {
              const index = draft.findIndex((d) => d.id === documentChange.id)
              if (index !== -1) {
                draft?.splice(index, 1)
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: [{ type: 'Change', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetDocumentTemplatesQuery,
  useCreateDocumentChangeMutation,
  useDeleteDocumentChangeMutation,
  useGetCCDCVersionsQuery,
  useUpdateDocumentChangeMutation,
  useMarkSupplementaryConditionsQACompleteMutation,
  useMarkAddendaQACompleteMutation,
} = documentChangeTemplateApi
