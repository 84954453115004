import React, { useCallback, useContext, useMemo } from 'react'
import { DocumentChangeSegment } from '../../shared/interfaces/project/document/changes/document-change.interface'
import DocumentReference from '../document-reference'
import { v4 as uuidv4 } from 'uuid'
import { Quads } from '../../shared/interfaces/quads.interface'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import { Route, useBackButton } from '../../hooks/use-back-button'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDocumentChange,
  setSelectedSources,
} from '../../redux/viewer-slice'
import {
  useDeleteDocumentChangeDestinationMutation,
  useGetAddendaSegmentsQuery,
} from '../../redux/api-slice'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import { skipToken } from '@reduxjs/toolkit/query'

interface AddendaQADestinationCardProps {
  destination: DocumentChangeSegment
}

const AddendaQADestinationCard: React.FC<AddendaQADestinationCardProps> = ({
  destination,
}) => {
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const [searchParams, setSearchParams] = useSearchParams()
  const { addToNavigationHistoryAndNavigate } = useBackButton()
  const { documentViewer } = useContext(DocumentViewerContext)
  const dispatch = useDispatch()
  const { currentData: addendaSegments } = useGetAddendaSegmentsQuery(
    currentDocument ? currentDocument?.id : skipToken
  )
  const [deleteDocumentChangeDestination] =
    useDeleteDocumentChangeDestinationMutation()
  const { pathname } = useLocation()
  const selectedDocumentChange = useSelector(selectDocumentChange)

  const addendaSegment = useMemo(() => {
    return addendaSegments?.find((segment) => segment.id === destination.id)
  }, [addendaSegments, destination.id])

  const onClickDocumentReference = useCallback(() => {
    const currentPage = documentViewer?.getCurrentPage()
    const highlightID = uuidv4()
    const destinationPage = destination.page
    const quads: Quads[] = destination?.quads
    if (!currentPage || !destination.document_uuid) {
      return
    }
    const route = pathname.split('/')[2]
    if (!route || !destination.document_uuid) {
      return
    }
    addToNavigationHistoryAndNavigate(
      route as Route,
      route as Route,
      destination.document_uuid,
      destinationPage
    )

    searchParams.set('page', destinationPage?.toString())
    setSearchParams(searchParams)

    dispatch(
      setSelectedSources([
        {
          id: highlightID,
          page: destinationPage,
          quads: quads ?? [],
          isPrimary: true,
          documentUUID: destination.document_uuid,
        },
      ])
    )
  }, [
    addToNavigationHistoryAndNavigate,
    destination.document_uuid,
    destination.page,
    destination?.quads,
    dispatch,
    documentViewer,
    pathname,
    searchParams,
    setSearchParams,
  ])

  const onClickDelete = useCallback(async () => {
    if (
      !destination?.id ||
      !selectedDocumentChange?.id ||
      !currentProject?.uuid
    ) {
      return
    }
    await deleteDocumentChangeDestination({
      documentChangeId: selectedDocumentChange.id,
      destinationId: destination.id,
      projectUUID: currentProject?.uuid,
    }).unwrap()
    dispatch(setSelectedSources([]))
  }, [
    destination.id,
    selectedDocumentChange?.id,
    currentProject?.uuid,
    deleteDocumentChangeDestination,
    dispatch,
  ])

  return (
    <div className="rounded border p-2 text-xs">
      <div>
        <div className="flex justify-between">
          <div className="text-indigo-500">
            <DocumentReference
              onClick={onClickDocumentReference}
              documentTitle={destination?.document_title ?? ''}
              pageNumber={destination.page}
            />
          </div>
          <button
            className="rounded border px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100"
            aria-label="Delete"
            onClick={onClickDelete}
          >
            Delete
          </button>
        </div>
        <div>
          <p>
            {(addendaSegment?.original_text?.length ?? 0) > 100
              ? `${addendaSegment?.original_text?.slice(0, 100)}...`
              : addendaSegment?.original_text ?? ''}
          </p>
        </div>
      </div>
    </div>
  )
}

export default AddendaQADestinationCard
