import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as Sentry from '@sentry/react'

import { WebsocketContext } from '../../../contexts/websocket-context'
import TextareaAutosize from 'react-textarea-autosize'
import {
  ChatBubbleLeftRightIcon,
  ClipboardDocumentIcon,
  EllipsisHorizontalIcon,
  PaperAirplaneIcon,
  StopIcon,
} from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import { skipToken } from '@reduxjs/toolkit/query'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../../redux/application-slice'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  ChatHistory,
  ChatMessage,
  ChatSessionMessage,
} from '../../../shared/interfaces/chat/chat-history.interface'
import { useGetChatHistoryQuery } from '../../../redux/api/chat-api-slice'
import { toast } from 'react-toastify'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../../utils/posthog-constants'
import { Project } from '../../../shared/interfaces/project/project.interface'
import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import AIChatHistory from './ai-chat-history'
import {
  useGetDocumentsListByProjectQuery,
  useGetUserProfileQuery,
} from '../../../redux/api-slice'
import AIChatSuggestion from './ai-chat-suggestion'
import AIChatMessage from './ai-chat-message'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import LoadingCircle from '../../loading/loading-circle'
import DocumentListboxMulti from '../../document-listbox/document-listbox-multi'
import {
  selectExplainQuery,
  setExplainQuery,
} from '../../../redux/viewer-slice'

export interface ChatSuggestion {
  topic: string
  suggestion: string
}

const suggestions: ChatSuggestion[] = [
  {
    topic: 'Design',
    suggestion: 'Who is responsible for design?',
  },
  {
    topic: 'Warranty',
    suggestion: 'How long is the warranty period?',
  },
]

function AiChat() {
  const [chatInput, setChatInput] = useState<string>('')
  const websocketContext = useContext(WebsocketContext)
  const { data: user } = useGetUserProfileQuery(undefined)
  const {
    onMessage,
    messages,
    thinking,
    connected,
    setMessages,
    setSessionUuid,
    sessionUuid,
    writing,
    onCancel,
    sendExplainRequest,
  } = websocketContext

  const currentDocument = useSelector(selectCurrentDocument)
  const dispatch = useDispatch()
  const explainQuery = useSelector(selectExplainQuery)
  const currentProject = useSelector(selectCurrentProject)
  const [projectState, setProjectState] = useState<Project | null>(
    currentProject
  )
  const [isInChatMode, setIsInChatMode] = useState(true)
  const endRef = useRef<HTMLDivElement | null>(null)
  const chatRef = useRef<HTMLTextAreaElement | null>(null)
  const [selectedChatHistory, setSelectedChatHistory] =
    useState<ChatHistory | null>(null)
  const [selectedDocuments, setSelectedDocuments] = useState<
    ProjectDocumentMetadata[]
  >([])
  const [thinkingTimer, setThinkingTimer] = useState<number | null>(null)

  const [searchParams, setSearchParams] = useSearchParams()

  const chatHistoryId = searchParams.get('chat_history_id')

  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken,
    {
      pollingInterval: 1000,
    }
  )

  const { currentData: chatHistory, refetch: refetchChatHistory } =
    useGetChatHistoryQuery(currentProject?.uuid ?? skipToken)
  const navigate = useNavigate()
  const posthog = usePostHog()

  const onNewChat = useCallback(() => {
    searchParams.delete('chat_history_id')
    setSearchParams(searchParams)
    onCancel()
    setTimeout(() => {
      chatRef?.current?.focus()
    }, 50)
    setIsInChatMode(true)
    setSelectedChatHistory(null)
    setMessages({})
    setSessionUuid(null)
  }, [searchParams, setSearchParams, onCancel, setMessages, setSessionUuid])

  const onOpenChatHistory = useCallback(
    (history: ChatHistory) => {
      onCancel()
      posthog?.capture(POSTHOG.chat_session_opened, {
        chat_session_uuid: history.uuid,
        project_uuid: currentProject?.uuid,
      })
      if (!history || !history.uuid) {
        return
      }
      setTimeout(() => {
        chatRef?.current?.focus()
      }, 50)
      setIsInChatMode(true)
      setSessionUuid(history.uuid ? history.uuid : null)
      setSelectedChatHistory({ ...history })
      if (searchParams.get('chat_history_id') !== history.uuid) {
        searchParams.set('chat_history_id', history.uuid)
        setSearchParams(searchParams)
      }
    },
    [
      currentProject?.uuid,
      posthog,
      searchParams,
      setSearchParams,
      setSessionUuid,
      onCancel,
    ]
  )

  const setChatHistoryBySessionUuid = useCallback(() => {
    if (!chatHistory) {
      return
    }
    const foundHistory = chatHistory?.find((c) => c.uuid === sessionUuid)
    if (!foundHistory) {
      return
    }
    setSelectedChatHistory({ ...foundHistory })
  }, [chatHistory, sessionUuid])

  const resetChatState = useCallback(() => {
    if (!projectState?.id || !currentProject?.id) {
      return
    }
    if (projectState.id !== currentProject?.id) {
      setIsInChatMode(false)
      setMessages({})
      setSessionUuid(null)
      setChatInput('')
      onCancel()
      setProjectState(currentProject)
    }
  }, [currentProject, onCancel, projectState?.id, setMessages, setSessionUuid])

  const setChatHistorySearchParam = useCallback(() => {
    if (!sessionUuid || searchParams.get('chat_history_id') === sessionUuid) {
      return
    }
    searchParams.set('chat_history_id', sessionUuid)
    setSearchParams(searchParams)
  }, [searchParams, sessionUuid, setSearchParams])

  const openHistoryBySearchParam = useCallback(() => {
    if (!chatHistory) {
      return
    }
    const foundHistory = chatHistory.find((x) => x.uuid === chatHistoryId)
    if (!foundHistory) {
      return
    }
    onOpenChatHistory(foundHistory)
  }, [chatHistory, chatHistoryId, onOpenChatHistory])

  useEffect(setChatHistorySearchParam, [setChatHistorySearchParam])
  useEffect(openHistoryBySearchParam, [openHistoryBySearchParam])
  useEffect(resetChatState, [resetChatState])
  useEffect(setChatHistoryBySessionUuid, [setChatHistoryBySessionUuid])

  useEffect(() => {
    if (messages) {
      if (!endRef) {
        return
      }
      endRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }, [messages])

  useEffect(() => {
    if (thinking) {
      setThinkingTimer(0)
    } else {
      setThinkingTimer(null)
    }
  }, [thinking])

  useEffect(() => {
    if (thinkingTimer === null) return

    if (thinkingTimer === 5) {
      posthog?.capture(POSTHOG.chat_slow)
      Sentry.captureMessage("Chat said 'Things are taking longer than usual'")
    }

    const intervalId = setInterval(() => {
      setThinkingTimer(thinkingTimer + 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [thinkingTimer, posthog])

  const messagesToDisplay = useMemo(() => {
    const messagesMapped: ChatSessionMessage[] = Object.values(messages).map(
      (message: ChatMessage) => {
        const chatSessionMessage: ChatSessionMessage = {
          message: message.content,
          origin: message.source === 'user' ? 'USER' : 'SYSTEM',
          date_created: new Date().toISOString(),
          message_sources: message.sources,
          filtered_documents: message?.filtered_documents,
          id: message.chat_session_message_id,
          conflict: message.conflict,
        }
        return chatSessionMessage
      }
    )
    return [...(selectedChatHistory?.messages ?? []), ...messagesMapped]
      .slice()
      .sort(
        (a, b) =>
          new Date(a?.date_created ?? '').getTime() -
          new Date(b?.date_created ?? '').getTime()
      )
      .map((c) => {
        return {
          date_created: c.date_created,
          message_sources: c.message_sources,
          message: c.message,
          origin: c.origin,
          filtered_documents: c?.filtered_documents,
          id: c?.id,
          conflict: c?.conflict,
        }
      })
  }, [selectedChatHistory, messages])

  const copyChatToClipboard = useCallback(async () => {
    let text = ''
    for (const message of messagesToDisplay) {
      if (message.origin === 'USER') {
        text += `${user?.first_name} ${user?.last_name}: ${message.message}\n`
      } else if (message.origin === 'SYSTEM') {
        text += `Provision: ${message.message}\n`
      }
    }
    await navigator.clipboard.writeText(text)
    toast.success('Chat copied to clipboard')
  }, [messagesToDisplay, user])

  const availableDocuments = useMemo(() => {
    if (!documents) {
      return []
    }
    return documents.filter(
      (document) =>
        document.job_status !== 'PENDING' &&
        document.job_status !== 'PROCESSING' &&
        document.job_status !== 'ARCHIVED' &&
        document.job_status !== 'UNARCHIVING'
    )
  }, [documents])

  const archivedDocuments = useMemo(() => {
    if (!documents) {
      return []
    }
    return documents.filter(
      (document) =>
        document.job_status === 'ARCHIVED' ||
        document.job_status === 'UNARCHIVING'
    )
  }, [documents])

  const onSendMesage = useCallback(
    (message: string) => {
      onMessage(
        message,
        selectedDocuments || availableDocuments.length === documents?.length
          ? selectedDocuments
            ? selectedDocuments.map((d) => d.id)
            : []
          : availableDocuments?.map((d) => d.id)
      )
      posthog?.capture(POSTHOG.chat_sent_message, {
        chat_session_uuid: sessionUuid,
        project_uuid: currentProject?.uuid,
      })
    },
    [
      onMessage,
      selectedDocuments,
      availableDocuments,
      documents?.length,
      posthog,
      sessionUuid,
      currentProject?.uuid,
    ]
  )

  useEffect(() => {
    if (
      !explainQuery ||
      !currentProject?.uuid ||
      !currentDocument?.uuid ||
      !chatHistory
    ) {
      return
    }
    onNewChat()
    sendExplainRequest(
      explainQuery,
      currentProject?.uuid ?? '',
      currentDocument?.uuid ?? ''
    )
    dispatch(setExplainQuery(null))
    setChatInput('')
  }, [
    currentDocument?.uuid,
    currentProject?.uuid,
    dispatch,
    explainQuery,
    onSendMesage,
    sendExplainRequest,
    onNewChat,
    chatHistory,
  ])

  const onClickSend = useCallback(() => {
    onSendMesage(chatInput)
    setChatInput('')
  }, [onSendMesage, chatInput])

  const documentsComplete = useMemo(() => {
    if (documents?.length === 0) {
      return false
    }
    return (
      documents?.some(
        (document) =>
          document.job_status !== 'PENDING' &&
          document.job_status !== 'PROCESSING' &&
          document.job_status !== 'ARCHIVED' &&
          document.job_status !== 'UNARCHIVING'
      ) ?? false
    )
  }, [documents])

  const onNavigateChatHistory = useCallback(() => {
    setIsInChatMode(false)
    setMessages({})
    setSessionUuid(null)
    setChatInput('')
    onCancel()
    setSearchParams({})
    try {
      refetchChatHistory()
    } catch (e) {
      // pass
    }
  }, [
    setMessages,
    setSessionUuid,
    onCancel,
    setSearchParams,
    refetchChatHistory,
  ])

  const navigateDocuments = useCallback(() => {
    if (documents && documents.length > 0) {
      return
    }
    navigate(`/${currentProject?.uuid}/documents`)
  }, [navigate, currentProject?.uuid, documents])

  const onCopyTranscriptClick = useCallback(() => {
    copyChatToClipboard()
  }, [copyChatToClipboard])

  const onKeyDownChat = useCallback(
    (e) => {
      if (!isInChatMode) {
        e.stopPropagation()
        e.preventDefault()
        return
      }
      if (e.key === 'Enter') {
        e.stopPropagation()
        e.preventDefault()
        onSendMesage(chatInput)
        setChatInput('')
      }
    },
    [chatInput, isInChatMode, onSendMesage]
  )

  const onSetChatInput = useCallback((e) => {
    setChatInput(e.target.value)
  }, [])

  const chatFooter = useMemo(() => {
    return (
      <div className="mb-1 border-t border-gray-300 p-3 pb-1">
        <div className="mb-2 flex space-x-2">
          <button
            type="button"
            className="flex flex-1 items-center justify-center rounded px-2 py-1.5 text-xs text-gray-600 ring-1 ring-inset ring-gray-200 hover:bg-gray-100"
            onClick={onNewChat}
          >
            <PlusIcon className="mr-2 h-4 w-4" />
            <span>New Conversation</span>
          </button>
          <button
            type="button"
            className="flex flex-1 items-center justify-center rounded px-2 py-1.5 text-xs text-gray-600 ring-1 ring-inset ring-gray-200 hover:bg-gray-100"
            onClick={onCopyTranscriptClick}
          >
            <ClipboardDocumentIcon className="mr-2 h-4 w-4" />
            <span>Copy Transcript</span>
          </button>
        </div>
        <div className="relative flex grow">
          <TextareaAutosize
            ref={chatRef}
            disabled={
              !connected ||
              !isInChatMode ||
              writing ||
              !availableDocuments.length
            }
            value={chatInput}
            onChange={onSetChatInput}
            onKeyDown={onKeyDownChat}
            placeholder={`${
              !connected
                ? 'Connection lost. Please check your internet connection or try again later.'
                : writing
                  ? 'Provision is Generating an Answer'
                  : 'Ask Provision a question'
            }`}
            className={`block w-full resize-none overflow-y-hidden rounded-md border-0 pr-12 text-gray-900 shadow ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
              isInChatMode ? ' bg-white' : ' bg-gray-100 '
            }'}`}
          />
          <button
            className="absolute bottom-1.5 right-2 rounded-md p-1 text-gray-500 transition-colors disabled:text-gray-400 disabled:opacity-40"
            onClick={onClickSend}
          >
            <PaperAirplaneIcon className="h-5 w-5" />
          </button>
        </div>
        <div className={'pt-0.5 text-center text-xs text-gray-400'}>
          Provision can make mistakes. Consider checking important information.
        </div>
      </div>
    )
  }, [
    chatInput,
    connected,
    isInChatMode,
    onClickSend,
    onSetChatInput,
    writing,
    onKeyDownChat,
    onNewChat,
    onCopyTranscriptClick,
    availableDocuments.length,
  ])

  return (
    <div className="flex h-full w-full min-w-0 flex-grow flex-col overflow-auto bg-white">
      {isInChatMode ? (
        <>
          <div className="flex flex-wrap items-center justify-between gap-2 border-b border-gray-200 bg-white p-1">
            <button
              disabled={!isInChatMode}
              type="button"
              className="justify-left flex items-center rounded bg-white px-3 py-1.5 text-xs font-semibold text-gray-700 hover:bg-gray-100"
              onClick={onNavigateChatHistory}
            >
              <ArrowLeftIcon className="mr-2 h-5 w-5" />
              Back to Chat History
            </button>
            <div className="w-96">
              <DocumentListboxMulti
                customClass="flex flex-1 items-center justify-start rounded px-2 py-1.5 text-xs text-gray-600 ring-1 ring-inset ring-gray-200 hover:bg-gray-100"
                documents={availableDocuments}
                customWidth="w-96"
                selectedDocuments={selectedDocuments}
                setSelectedDocuments={setSelectedDocuments}
              />
            </div>
          </div>
          <div className="w-full flex-auto overflow-auto bg-gray-50">
            {messagesToDisplay.length !== 0 && (
              <div className="p-2">
                {messagesToDisplay?.map(
                  (message: ChatSessionMessage, index: number) => {
                    return (
                      <AIChatMessage
                        writing={writing}
                        key={`${message.message}${index}`}
                        chatHistoryMessage={message}
                        index={index}
                        selectedChatHistory={selectedChatHistory}
                        documents={documents ?? []}
                        sessionUuid={sessionUuid ?? ''}
                        isLast={messagesToDisplay.length - 1 === index}
                        query={
                          index > 0
                            ? messagesToDisplay[index - 1]?.message ?? ''
                            : ''
                        }
                      />
                    )
                  }
                )}
                {thinking && (
                  <div className={'flex w-full justify-end px-2 pb-2 pt-1'}>
                    <div className={'self-end'}>
                      <div
                        className={'rounded border bg-white p-1 text-sm shadow'}
                      >
                        {(thinkingTimer ?? 0) <= 10 ? (
                          <EllipsisHorizontalIcon
                            width={30}
                            className={'animate-pulse'}
                          />
                        ) : (
                          <div className="animate-pulse">
                            Analyzing...
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="h-0 w-0" ref={endRef} />
              </div>
            )}
          </div>
          <div className="flex-initial">
            {!thinking && messagesToDisplay.length === 0 && (
              <div className="space-y-2 px-3 pb-3">
                <div className="w-full gap-2 text-xs text-gray-700 underline hover:no-underline">
                  <a
                    className="text-xs text-gray-700 underline hover:no-underline"
                    href="https://www.loom.com/share/4a8ff1f3663949248abe74a66d5d5dc6?sid=1cf1eb8a-fddb-4c94-8510-3eecfdfc96d0"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Learn how to ask questions with Provision
                  </a>
                </div>
                <div className="flex flex-col gap-2">
                  {suggestions?.map((suggestion) => (
                    <AIChatSuggestion
                      suggestion={suggestion}
                      key={suggestion.suggestion}
                      onSendMesage={onSendMesage}
                      setChatInput={setChatInput}
                    />
                  ))}
                </div>
              </div>
            )}
            {messagesToDisplay.length !== 0 && (
              <div className="h-8 bg-gray-50">
                {writing && !thinking && (
                  <div className="flex h-full justify-center pb-1 pl-4 pr-3">
                    <button
                      onClick={onCancel}
                      className="flex h-7 w-1/2 items-center justify-center rounded px-2 py-1.5 text-xs text-gray-600 ring-1 ring-inset ring-gray-200 hover:bg-gray-100"
                    >
                      <StopIcon className="mr-2 h-4 w-4" /> Stop Generation
                    </button>
                  </div>
                )}
              </div>
            )}
            {archivedDocuments?.length ? (
              <div className="flex space-x-2 bg-gray-50 p-1 text-xs">
                <div>
                  Some documents are archived and cannot be used. Chatting using{' '}
                  {availableDocuments.length} / {documents?.length} total
                  documents.
                </div>
              </div>
            ) : (
              availableDocuments.length !== documents?.length &&
              documents?.length && (
                <div className="flex space-x-2 bg-gray-50 p-1 text-xs">
                  <div>
                    <LoadingCircle
                      isSpinning
                      className="h-4 w-4 animate-spin fill-blue-600"
                    />
                  </div>
                  <div>
                    Some documents are still processing. Chatting using{' '}
                    {availableDocuments.length} / {documents?.length} total
                    documents.
                  </div>
                </div>
              )
            )}

            {chatFooter}
          </div>
        </>
      ) : (
        <>
          <div className="w-full flex-auto overflow-auto">
            {chatHistory && chatHistory.length > 0 ? (
              <div className="flex h-full flex-col p-2">
                <div className="px-2 pt-2">
                  {!currentProject ? '' : 'Chat History'}
                </div>
                <div className="flex-1 overflow-y-auto pt-2">
                  {chatHistory
                    .slice()
                    .sort((a, b) =>
                      a.saved === b.saved ? 0 : a.saved ? -1 : 1
                    )
                    .map((history) => (
                      <AIChatHistory
                        key={`chat_history_${history.uuid}`}
                        history={history}
                        onOpenChatHistory={onOpenChatHistory}
                      />
                    ))}
                </div>
              </div>
            ) : (
              <div
                className={
                  'my-12 flex flex-col items-center gap-2 self-center text-center text-gray-500'
                }
              >
                <div className="flex w-56 flex-col items-center justify-center">
                  <ChatBubbleLeftRightIcon width={32} />
                  <div className={'text-black'}>Provision AI Chat</div>
                  <button
                    disabled={documents && documents.length > 0}
                    onClick={navigateDocuments}
                    className={`text-sm ${
                      documents?.length === 0
                        ? 'cursor-pointer hover:text-indigo-500 hover:underline'
                        : ''
                    }`}
                  >
                    {`${
                      documents?.length === 0
                        ? 'Upload a document to begin a conversation with Provision'
                        : 'Ask Provision AI a question about your project by starting a conversation below.'
                    }`}
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="mb-3 flex flex-initial">
            <button
              type="button"
              disabled={!currentProject || !documentsComplete}
              className={`flex w-full justify-center gap-2 rounded-md px-3.5 py-2.5 text-sm font-semibold  text-white shadow-sm ring-1 ring-inset ring-gray-300   focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                !currentProject || !documentsComplete
                  ? 'bg-gray-300'
                  : 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
              }`}
              onClick={onNewChat}
            >
              <PlusIcon className="h-5 w-5" />
              Start a New Conversation
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default AiChat
