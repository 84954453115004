import React from 'react'
import { useDispatch } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import { setModal } from '../../../redux/application-slice'
import { MODAL_TYPES } from '../../modals/modal-controller'
import { userPermissionsOptions } from '../../../shared/interfaces/project/risk/risk-inteface'

interface RiskProps {
  id: string
  riskName: string
  queryText: string
  permissions?: userPermissionsOptions
  isLoadingRiskLists?: boolean
}

const Risk: React.FC<RiskProps> = ({
  id,
  riskName,
  queryText,
  permissions,
  isLoadingRiskLists,
}) => {
  const dispatch = useDispatch()

  const handleEditClick = (e: React.MouseEvent) => {
    dispatch(
      setModal({
        modal: MODAL_TYPES.EDIT_CUSTOM_RISK,
        props: { id, riskName, queryText },
      })
    )
  }

  const handleDeleteClick = (e: React.MouseEvent) => {
    dispatch(
      setModal({
        modal: MODAL_TYPES.DELETE_CUSTOM_RISK,
        props: {
          id,
          risk_name: riskName,
        },
      })
    )
  }

  const isDisabled = !permissions?.can_change || isLoadingRiskLists
  const getTooltipContent = isDisabled
    ? 'You do not have permission to perform this action.'
    : undefined

  const disabledButtonClassNames =
    'cursor-not-allowed bg-gray-100 text-gray-400'
  const enabledButtonClassNames = 'text-gray-700 hover:bg-gray-100'

  return (
    <div className="mb-2 flex w-full max-w-full items-center justify-between gap-x-2 rounded-lg border border-gray-200 p-4">
      <div className="min-w-0 flex-grow">
        <div className="truncate text-lg font-medium">{riskName}</div>
        <div className="text-sm text-gray-600">{queryText}</div>
      </div>
      <div className="flex shrink-0 space-x-2">
        <button
          onClick={handleEditClick}
          className={`rounded px-3 py-1 text-sm font-medium ${
            isDisabled ? disabledButtonClassNames : enabledButtonClassNames
          }`}
          disabled={isDisabled}
          data-tooltip-id="button-tooltip"
          data-tooltip-content={getTooltipContent}
          aria-label="Edit Details"
        >
          Edit Details
        </button>
        <button
          onClick={handleDeleteClick}
          className={`rounded px-3 py-1 text-sm font-medium ${
            isDisabled ? disabledButtonClassNames : enabledButtonClassNames
          }`}
          disabled={isDisabled}
          data-tooltip-id="button-tooltip"
          data-tooltip-content={getTooltipContent}
          aria-label="Delete"
        >
          Delete
        </button>
        <Tooltip id="button-tooltip" place="left" />
      </div>
    </div>
  )
}

export default Risk
