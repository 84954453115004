import React from 'react'
import { useDispatch } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import { setModal } from '../../../redux/application-slice'
import { RiskListProps } from '../../../shared/interfaces/project/risk/risk-inteface'
import { MODAL_TYPES } from '../../modals/modal-controller'
import { useRunRiskListReviewMutation } from '../../../redux/api/project-risk-api-slice'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'

const RiskList: React.FC<RiskListProps> = ({
  id,
  name,
  projectUuid,
  userPermissions,
  isLoadingRiskLists,
}) => {
  const dispatch = useDispatch()
  const [runRiskListReview] = useRunRiskListReviewMutation()
  const location = useLocation()
  const navigate = useNavigate()

  const handleEditClick = (e: React.MouseEvent) => {
    dispatch(
      setModal({ modal: MODAL_TYPES.EDIT_RISK_LIST, props: { id, name } })
    )
  }

  const handleDeleteClick = (e: React.MouseEvent) => {
    dispatch(
      setModal({ modal: MODAL_TYPES.DELETE_RISK_LIST, props: { id, name } })
    )
  }

  const handleViewRisksClick = (e: React.MouseEvent) => {
    navigate(`${location.pathname}/${id}`)
  }

  const handleRunClick = async (e: React.MouseEvent) => {
    try {
      await runRiskListReview({
        risklist_template_id: id,
        project_uuid: projectUuid,
      }).unwrap()
      toast.success(`Risk list review for ${name} started successfully`)
    } catch (error) {
      toast.error(`Failed to start risk list review for ${name}`)
    }
  }

  const getTooltipContent = (permission: keyof typeof userPermissions) => {
    return !userPermissions?.[permission]
      ? 'You do not have permission to perform this action.'
      : undefined
  }

  const disabledButtonClassNames =
    'cursor-not-allowed bg-gray-100 text-gray-400'
  const enabledButtonClassNames = 'text-gray-700 hover:bg-gray-100'

  return (
    <div className="mb-2 flex items-center justify-between rounded-lg border border-gray-200 p-4">
      <span className="text-lg font-medium">{name}</span>
      <div className="flex space-x-2">
        <button
          onClick={handleRunClick}
          className={`rounded px-3 py-1 text-sm font-medium ${
            userPermissions?.can_run
              ? enabledButtonClassNames
              : disabledButtonClassNames
          }`}
          aria-label="Run"
          disabled={!userPermissions?.can_run}
          data-tooltip-id="button-tooltip"
          data-tooltip-content={getTooltipContent('can_run')}
        >
          Run
        </button>
        <button
          onClick={handleViewRisksClick}
          className={`rounded px-3 py-1 text-sm font-medium ${
            userPermissions?.can_view
              ? enabledButtonClassNames
              : disabledButtonClassNames
          }`}
          aria-label="View Details"
          disabled={!userPermissions?.can_view}
          data-tooltip-id="button-tooltip"
          data-tooltip-content={getTooltipContent('can_view')}
        >
          View Details
        </button>
        <button
          onClick={handleEditClick}
          className={`rounded px-3 py-1 text-sm font-medium ${
            userPermissions?.can_change && !isLoadingRiskLists
              ? enabledButtonClassNames
              : disabledButtonClassNames
          }`}
          aria-label="Edit Title"
          disabled={!userPermissions?.can_change || isLoadingRiskLists}
          data-tooltip-id="button-tooltip"
          data-tooltip-content={getTooltipContent('can_change')}
        >
          Edit Title
        </button>
        <button
          onClick={handleDeleteClick}
          className={`rounded px-3 py-1 text-sm font-medium ${
            userPermissions?.can_change && !isLoadingRiskLists
              ? enabledButtonClassNames
              : disabledButtonClassNames
          }`}
          aria-label="Delete"
          disabled={!userPermissions?.can_change || isLoadingRiskLists}
          data-tooltip-id="button-tooltip"
          data-tooltip-content={getTooltipContent('can_change')}
        >
          Delete
        </button>
        <Tooltip id="button-tooltip" place="left" />
      </div>
    </div>
  )
}

export default RiskList
