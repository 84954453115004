import React, { useCallback } from 'react'
import { useGetUserProfileQuery } from '../../redux/api-slice'
import { useSelector } from 'react-redux'
import { useMarkSupplementaryConditionsQACompleteMutation } from '../../redux/api/document-change-template-api-slice'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'

const MarkSCCompleteButton: React.FC = () => {
  const { data: user } = useGetUserProfileQuery(undefined)
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const [markSCQAComplete] = useMarkSupplementaryConditionsQACompleteMutation()

  const onMarkSCComplete = useCallback(
    (e) => {
      if (!currentProject?.id) {
        return
      }
      markSCQAComplete({
        project: currentProject?.id,
        uuid: currentDocument?.uuid,
        id: currentDocument?.id,
      })
    },
    [
      currentDocument?.id,
      currentDocument?.uuid,
      currentProject?.id,
      markSCQAComplete,
    ]
  )

  return user?.feature_flags?.editor_mode === true ? (
    <>
      <button
        className={`rounded px-2 py-1 text-sm font-medium ring-1 ring-inset ring-gray-300  ${currentDocument?.review?.review_type === 'SUPPLEMENTARY_CONDITIONS' ? 'bg-white text-gray-900 hover:bg-gray-50' : 'bg-gray-100 text-gray-400'}`}
        onClick={onMarkSCComplete}
        disabled={
          currentDocument?.review?.review_type !== 'SUPPLEMENTARY_CONDITIONS'
        }
      >
        Mark supplementary conditions as complete
      </button>
    </>
  ) : null
}

export default MarkSCCompleteButton
